$system-fonts: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
$font: 'Montserrat', sans-serif;
$font-serif: 'Playfair Display', serif;

// Typography Weight
$f-light: 300;
$f-regular: 400;
$f-medium: 500;
$f-semibold: 600;
$f-bold: 700;
$f-black: 800;
