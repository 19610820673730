.mainHeader {
  padding: rem(20) 0;
  background-color: $white;
  position: static;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: rem(80);
  z-index: 2;

  @include breakpoint(sm) {
    position: fixed;
  }

  &.has-shadow {
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  }

  &--logo {
    img {
      width: rem(160);
      min-width: rem(160);
      vertical-align: middle;
    }
  }

  &--nav {
    margin-left: auto;
    margin-right: rem(20);

    @include breakpoint(md) {
      margin: 0;
    }

    &__item {
      margin-left: rem(15);

      &:first-of-type {
        margin-left: 0;
      }

      a {
        color: $black;
        font-size: rem(13);

        &:after {
          content: '';
          position: absolute;
          width: 50%;
          left: 0;
          right: 0;
          margin: 0 auto;
          bottom: rem(-10);
          height: 0.125rem;
          background-color: $black;
          transition: all 0.225s ease;
          transform: scale(0);
        }

        &:hover {
          &:after {
            transform: scale(1);
          }
        }
      }

      &.active,
      &.is-active {
        a:after {
          transform: scale(1);
        }
      }
    }

    &__cta {
      margin-left: rem(20);
    }
  }
}
