.ubicacion-hero {
  background-color: $black;
  padding: rem(25) 0;
  height: rem(662);


  h2 {
    margin-bottom: rem(60);
  }

  ul {
    li {
      color: $white;
      line-height: 1.875;
      font-weight: $f-light;
    }
  }

  .btn {
    width: rem(274);
    margin-top: rem(22);
  }
}

.ubicacion-mapa {
  border: 2px solid $black;
  max-width: rem(704);
  width: 100%;
  height: rem(300);
  background-size: cover;
  background-position: center;
  margin-top: rem(30);

  @include breakpoint(sm) {
    width: rem(400);
    margin-bottom: 0;
    height: rem(500);
  }

  @include breakpoint(md) {
    width: rem(600);
  }

  @include breakpoint(lg) {
    width: rem(704);
  }

  &--overlay {
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.75);
    opacity: 0;
    visibility: hidden;
    transition: all 0.225s ease;
    z-index: 1;

    span {
      margin: 0;
    }
  }

  &:hover {

    .ubicacion-mapa--overlay {
      opacity: 1;
      visibility: visible;
    }
  }
}
