.whatsapp {
  background-color: #25d366;
  width: rem(60);
  height: rem(60);
  position: fixed;
  right: rem(20);
  bottom: rem(20);
  z-index: 2;
  border-radius: 100%;
}
