.lang {
  background-color: $white;
  padding: 0 rem(10);
  z-index: 1;
  top: rem(170);
  right: 0;

  li {
    margin: rem(12) 0;
  }
}
