.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background-color: $white;
  backface-visibility: hidden;
  visibility: hidden;
  transition: all .3s cubic-bezier(0,0,.2,1);
  transform: translate3d(0,-100%,0);
  text-align: left;
  overflow: hidden;
  padding: rem(45) rem(20) 0 rem(20);

  &.is-open {
    transform: translateY(0);
    visibility: visible;
  }

  li {
    line-height: 1.75;

    a {
      color: $black;
      font-size: rem(16);

      span {
        margin-left: rem(8);
      }
    }
  }
}

.lang-mobile {
  margin-top: rem(20);
  border-top: 1px solid $gray;
  padding-top: rem(20);

  li {
    margin-bottom: rem(7);
  }

}

.menu-social {
  margin-top: rem(20);
  border-top: 1px solid $gray;
  padding-top: rem(20);

  li {
    margin-bottom: rem(7);
  }

  svg {
    path {
      fill: $black;
    }
  }
}

.menu-close {
  position: absolute;
  top: rem(31);
  right: rem(23);
}
