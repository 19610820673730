.como-llegar {
  padding: rem(30) 0;

  @include breakpoint(sm) {
    padding: rem(100) 0;
  }

  h2 {
    line-height: 0.8;
  }

  &--aclaracion {
    width: 100%;
    margin: rem(50) auto 0 auto;
    line-height: 1.5;
    font-weight: $f-medium;

    @include breakpoint(md) {
      width: 50%;
    }
  }
}

.transportes-list {
  margin-top: rem(30);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
  grid-column-gap: rem(30);
  grid-row-gap: rem(50);

  @include breakpoint(sm) {
    margin-top: rem(50);
  }
}

.transporte {

  i {
    margin-bottom: rem(15);
  }

  h5 {
    font-size: rem(16);
    font-weight: $f-semibold;
    font-family: $font;
    margin-bottom: rem(10);

    @include breakpoint(sm) {
      font-size: rem(18);
    }

    &:before {
      content: none;
    }
  }
}

.transporte-block {
  border: 1px solid $gray-dark;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($main-color, 0.38);
    left: rem(10);
    top: rem(10);
    z-index: -1;
  }

  > div {
    background-color: $white;
    padding: rem(18) rem(22);
    border-bottom: 1px solid $gray-dark;

    &:last-of-type {
      border-bottom: 0 none;
    }

    h4 {
      margin-bottom: rem(10);
    }

    p {
      line-height: 1.625;
      margin-bottom: 1.25rem;
    }

    ul {
      list-style: disc;
      margin-left: rem(20);

      li {
        font-weight: $f-light;
        line-height: 1.625;
      }
    }

    .time,
    .price {
      font-size: rem(14);
      color: #919191;
      font-weight: $f-semibold;
    }

    .time {
      margin-right: rem(8);
    }
  }

  .btn {
    border: 1px solid $black;
    color: $black;
    background: #000;
    font-weight: $f-medium;
    padding: rem(5) rem(15);
    background-color: $white;

    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}
