.faqs {
  padding-bottom: rem(50);

  @include breakpoint(sm) {
    padding-bottom: rem(100);
  }

  h2 {
    line-height: 0.85;
  }

  a {
    color: $black;
    text-decoration: underline;
  }

  [data-accordion] {
    border: 2px solid $black;
    max-width: rem(930);
    margin: 0 auto;
    margin-bottom: rem(25);

    &.is-open {
      [data-trigger]  {
        i {
          &:before {
            display: none;
          }

          &:after {
            display: block;
          }
        }
      }
    }
  }

  [data-trigger] {
    cursor: pointer;
    padding: rem(20) rem(15);
    width: 100%;

    span {
      display: block;
      font-size: rem(24);
      font-weight: $f-semibold;
      text-align: left;

      @include breakpoint(sm) {
        font-size: rem(32);
      }
    }

    i {
      background-color: $black;
      width: rem(36);
      height: rem(36);
      top: 0;
      right: 0;

      &:before,
      &:after {
        color: $white;
        font-size: rem(30);
        font-family: $font;
        font-weight: $f-bold;
      }

      &:before {
        content: '+';
      }

      &:after {
        content: '-';
        display: none;
      }
    }
  }
}


.faq-content {
  padding: 0 rem(35) rem(20) rem(15);

  li,
  p {
    font-weight: $f-light;
    line-height: 1.625;
    margin-bottom: rem(8);
  }

  ul {
    list-style: disc;
    margin-left: rem(20);

    li {
      margin-bottom: rem(2);
    }
  }
}

.faq-toggle {
  margin: 0 auto;
}
.faq-hidden {
  display: none;
}
