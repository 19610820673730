.blog {
  padding-top: rem(20);
  margin-bottom: rem(10);

  @include breakpoint(md) {
    padding-top: rem(60);
    margin-bottom: rem(75);
  }

  &--list {
    margin-top: rem(80);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
    grid-column-gap: rem(30);
    grid-row-gap: rem(30);
  }

  .btn {
    width: 100%;
    display: flex;
    margin: rem(50) auto;

    @include breakpoint(sm) {
      width: rem(200);
    }
  }
}

.post {
  border: 2px solid $black;

  a {
    height: 100%;
  }

  &--title {
    font-size: rem(24);
    color: $black;
    font-weight: $f-semibold;
    margin-bottom: rem(20);

    @include breakpoint(sm) {
      font-size: rem(28);
    }
  }

  &--img {
    height: rem(204);
    object-fit: cover;
    width: 100%;
    vertical-align: middle;
  }

  &--content {
    padding: rem(20);
  }

  &--text {
    color: $black;
    font-size: rem(14);
  }
}
