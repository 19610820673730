.habitacion-info {
  margin-top: rem(40);
  padding-bottom: 0;

  @include breakpoint(sm) {
    margin-top: rem(125);
    padding-bottom: rem(50);
  }

  ul {
    li {
      color: #4A4848;
      font-weight: 300;
      line-height: 1.5;
      margin-bottom: rem(5);
    }
  }

  h2 {
    line-height: 1;
    margin-bottom: rem(15);

    @include breakpoint(sm) {
      line-height: 0.8;
    }
  }
}
