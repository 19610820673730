.habitacion {
  &--img,
  &--text {
    width: 100%;

    @include breakpoint(md) {
      width: 50%;
    }
  }

  &--img {
    height: rem(320);
    background-size: cover;
    background-position: center;

    @include breakpoint(md) {
      height: rem(640);
    }
  }

  &--text {
    padding: rem(40) rem(30);

    @include breakpoint(md) {
      padding: rem(120) rem(75) 0 rem(75);
    }
  }

  &:nth-child(even){
    .habitacion--img {
      order: -1;

      @include breakpoint(md) {
        order: 2;
      }
    }
  }

  h5 {
    margin-bottom: rem(20);

    &:before {
      content: none;
    }
  }

  p {
    margin-bottom: rem(20);
  }
}
