.badges {
  margin-top: rem(50);
}

.badge {
  height: rem(192);
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.10), 0 0 12px 0 rgba(0,0,0,0.10);
  border-radius: 8px;
  background-color: $white;
  padding: rem(20) 0;
  margin: rem(25) auto;

  h4 {
    color: #414040;
    font-size: rem(22);
  }
}
