.ubicacion {
  padding: rem(50) 0;

  @include breakpoint(sm) {
    padding: rem(50) 0 rem(100) 0;
  }

  &--content {
    margin-top: rem(20);
    margin-bottom: rem(20);

    @include breakpoint(md) {
      margin-bottom: 0;
      margin-top: rem(100);
    }
  }

  &--img {
    margin-top: rem(20);
  }

  h3 {
    margin-bottom: rem(10);
  }

  p {
    margin-bottom: rem(35);
  }

  .btn {
    width: 100%;
    display: flex;

    @include breakpoint(sm) {
      width: rem(200);
    }
  }
}
