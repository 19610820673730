.habitaciones-hero {
  background-color: $black;
  padding: rem(65) 0;

  h1 {
    margin-bottom: rem(30);
  }

  p {
    color: $white;
  }
}
