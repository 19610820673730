.habitacion-hero {
  background-size: cover;
  background-position: center;
  height: rem(380);
  position: relative;
  padding: 0 rem(20);

  @include breakpoint(sm) {
    height: rem(406);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    opacity: 0.5;
  }

  h3 {
    font-family: $font-serif;
    font-weight: $f-bold;
    font-size: rem(36);
    color: $white;
    text-shadow:$text-shadow;
    margin-bottom: rem(10);

    @include breakpoint(sm) {
      font-size: rem(42);
    }
  }

  &--content {
    z-index: 1;
    width: 100%;
    height: 100%;

    @include breakpoint(md) {
      width: 50%;
    }
  }

  .btn {
    width: rem(300);
    height: rem(55);
    margin: 0 auto;
  }
}

.totalGuests {
  font-weight: $f-medium;
  color: $white;
  letter-spacing: 0.2px;
  text-shadow:$text-shadow;
}
