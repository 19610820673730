.flatpickr-day {
  &.selected {
    background-color: $main-color;
    border-color: $main-color
  }

  &.inRange {
    background-color: $main-color;
    box-shadow: -5px 0 0 $main-color, 5px 0 0 $main-color;
    border-color: $main-color;
    color: $white;
  }
}


.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: $main-color;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 $main-color;
}

.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover {
  background-color: $main-color;
  border-color: $main-color
}
