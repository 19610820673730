button {
  @include reset-btn;
}

.btn {
  font-size: rem(16);
  transition: color $transition-duration ease, background-color $transition-duration ease;
}

.btn--primary {
  color: $main-color;
  border: 2px solid $main-color;
  font-weight: $f-medium;
  padding: rem(6) rem(30);

  &:hover {
    background-color: $main-color;
    color: $white;
  }
}

.btn--full {
  background-color: $main-color;
  color: $white;

  &:hover {
    background-color: darken($main-color, 5%);
  }
}

.btn--big {
  padding: rem(11) rem(42);
}

.btn--secondary  {
  color: $black;
  border: 2px solid $black;
  font-weight: $f-bold;
  padding: rem(20);

  svg g {
    transition: stroke $transition-duration ease;
  }

  &:hover {
    background-color: $black;
    color: $white;

    svg g {
      stroke: $white;
    }
  }
}

.btn--white {
  background: transparent;
  color: $white;
  border: 2px solid $white;
  font-weight: $f-bold;
  padding: rem(20);

  svg g {
    stroke: $white;
    transition: stroke $transition-duration ease;
  }

  &:hover {
    background-color: $white;
    color: $black;

    svg g {
      stroke: $black;
    }
  }
}
