.actividades {
  padding-top: 0;

  &--list {
    grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
    grid-column-gap: rem(30);
    grid-row-gap: rem(30);
    display: flex;
    overflow: scroll;
    padding-bottom: rem(20);
    margin-right: rem(-20);
    margin-top: rem(25);

    @include breakpoint(sm) {
      display: grid;
      overflow: inherit;
      padding-bottom: 0;
      margin-right: 0;
      margin-top: 0;
    }
  }

  &--block {
    min-width: rem(280);
    margin-right: rem(20);

    @include breakpoint(sm) {
      margin-right: 0;
    }

    &__img {
      height: rem(190);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin-bottom: rem(20);
    }

    &__content {
      width: 100%;
    }
  }

  h5 {
    padding-top: rem(5);
    margin-bottom: rem(10);
  }

  p {
    font-size: rem(14);

    @include breakpoint(sm) {
      font-size: rem(16);
    }
  }

  a {
    border: 1px solid $black;
    color: $black;
    background: #000;
    font-weight: $f-medium;
    padding: rem(5) rem(15);
    background-color: $white;
    display: table;
    margin-top: rem(10);

    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}

.tango-show {
  .actividades--block__img {
    background-image: url(../images/tango-show.jpg);
  }
}

.walking-tour {
  .actividades--block__img {
    background-image: url(../images/walking-tour.jpg);
  }
}

.city-tour {
  .actividades--block__img {
    background-image: url(../images/city-tour.jpg);
  }
}

.alquiler-bicicletas {
  .actividades--block__img {
    background-image: url(../images/alquiler-bicicletas.jpg);
  }
}

.futbol {
  .actividades--block__img {
    background-image: url(../images/futbol.jpg);
  }
}

.clases-tango {
  .actividades--block__img {
    background-image: url(../images/clases-tango.jpg);
  }
}

.uruguay {
  .actividades--block__img {
    background-image: url(../images/uruguay.jpg);
  }
}

.dia-campo {
  .actividades--block__img {
    background-image: url(../images/dia-campo.jpg);
  }
}

.polo-day {
  .actividades--block__img {
    background-image: url(../images/polo-day.jpg);
  }
}

.clases-esp {
  .actividades--block__img {
    background-image: url(../images/clases-esp.jpg);
  }
}

.cata-vino {
  .actividades--block__img {
    background-image: url(../images/cata-vino.jpg);
  }
}

.masajes {
  .actividades--block__img {
    background-image: url(../images/masajes.jpg);
  }
}
