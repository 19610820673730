@include flexGrid;

.container {
  position: relative;
  width: 100%;
  max-width: rem(1440);
  margin: 0 auto;
  padding: 0 rem(20);

  @include breakpoint(md) {
    padding: 0 rem(40);
  }
}
