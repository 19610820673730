.choices {
  font-size: rem(14);
  font-weight: $f-light;

  &__inner {
    border: 1px solid $black;
    border-radius: 0;
    background-color: $white;
    width: 100%;
    padding: 0;
    padding-left: rem(8);
    text-align: left;
    min-height: rem(38);

    @include breakpoint(md) {
      width: rem(190);
    }
  }

  &.is-open,
  &.is-focused {
    .choices__inner {
      border-color: $black;
      border-radius: 0;
    }
  }

  &__list {
    &--dropdown {
      border: 1px solid $black;
    }
  }
}

.choices__list--single {
  height: rem(36);
  line-height: rem(40);
  padding: 0;
  padding-left: rem(8);
}

.is-open .choices__list--dropdown {
  border: 1px solid $black;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: $black;
  color: $white;
}

.choices[data-type*="select-one"] .choices__inner {
  padding: 0;
}


.choices[data-type*="select-one"]:after {
  content: '';
  height: 0.45em;
  width: 0.45em;
  border-style: solid;
  border-width: 2px 2px 0 0;
  position: absolute;
  right: rem(12);
  top: 50%;
  margin-top: rem(-4);
  pointer-events: none;
  border-color: $black;
  display: inline-block;
  vertical-align: center;
  transition: all 0.225s ease;
  transform: rotate(135deg);
  transform-origin: center;
}

.choices.is-focused.is-open:after {
  content: '';
  height: 0.45em;
  width: 0.45em;
  border-style: solid;
  border-width: 2px 2px 0 0;
  position: absolute;
  right: rem(12);
  top: 50%;
  margin-top: rem(-4);
  pointer-events: none;
  border-color: $black;
  display: inline-block;
  vertical-align: center;
  transition: all 0.225s ease;
  transform: rotate(135deg);
  transform-origin: center;
}
