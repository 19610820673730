.habitaciones {
  margin-top: 0;
  padding: 0 0 rem(25) 0;

  @include breakpoint(sm) {
    margin: 0;
    padding-top: rem(100);
  }

  &--main {
    height: auto;
    background-image: url(../images/habitaciones-bg.jpg);
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top: rem(25);

    @include breakpoint(sm) {
      height: rem(680);
      padding-top: 0;
    }
  }

  &--content {
    margin-top: rem(30);
  }

  &--imgs {
    margin-top: rem(50);
  }

  h2 {
    top: 0;

    @include breakpoint(md) {
      top: rem(-75);
    }
  }

  h3,
  p {
    color: $white;
  }

  h3 {
    margin-bottom: rem(10);
  }

  p {
    margin-bottom: rem(26);
  }

  .btn {
    width: 100%;
    display: flex;

    @include breakpoint(sm) {
      width: rem(250);
    }
  }
}

.slider-wrap {
  max-width: rem(930);
  margin: 0 auto;
}
