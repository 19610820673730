.booking {
  background-color: $white;
  padding: rem(16) rem(12) rem(14) rem(12);
  margin-bottom: rem(10);
  width: 100%;

  @include breakpoint(sm) {
    width: 75%;
  }

  @include breakpoint(md) {
    width: auto;
  }

  &--group {
    margin-right: 0;
    margin-bottom: rem(25);

    @include breakpoint(md) {
      margin-bottom: 0;
      margin-right: rem(12);
    }
  }

  &--input  {
    height: rem(38);
    border: 1px solid $black;
    width: 100%;
    padding-left: rem(8);
    font-size: rem(14);
    font-weight: $f-light;
    padding-top: rem(4);

    @include breakpoint(md) {
      width: rem(190);
    }

    &:focus {
      outline: none;
    }
  }

  &--label {
    font-size: rem(11);
    font-weight: $f-medium;
    background-color: $white;
    padding: 0 rem(2);
    top: rem(-5);
    left: rem(8);
    z-index: 1;
  }

  &--icon {
    top: rem(7);
    right: rem(5);
    pointer-events: none;
  }

  .btn {
    height: rem(38);
    margin-left: 0;

    @include breakpoint(md) {
      margin-left: rem(20);
    }
  }

  + p {
    color: $white;
    font-size: rem(15);
    text-shadow: $text-shadow;
    font-weight: $f-regular;
    letter-spacing: 0.1px;
  }
}
