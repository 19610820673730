.modal-promocion {
  position: fixed;
  bottom: rem(50);
  right: 0;
  width: rem(600);
  padding: rem(32);
  background-color: $white;
  border: 1px solid $black;
  z-index: 99;
  backface-visibility: hidden;
  visibility: hidden;
  transition: all .3s cubic-bezier(0,0,.2,1);
  transform: translate3d(100%, 0,0);

  &.is-visible {
    transform: translate3d(100%, 0,0);
    visibility: hidden;

    @include breakpoint(md) {
      transform: translateY(0);
      visibility: visible;
    }
  }

  &--close {
    top: rem(15);
    right: rem(15);
    cursor: pointer;
  }

  h3 {
    margin-bottom: rem(15);
  }

  p {
    margin-bottom: rem(15);
  }

  .btn {
    width: rem(275);
    height: rem(50);
    margin: rem(10) auto;
  }
}
