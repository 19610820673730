.habitacion-servicios {
  margin: rem(50) 0 0 0;

  @include breakpoint(md) {
    margin: rem(80) 0;
  }

  h2 {
    line-height: 1;
    margin-bottom: rem(15);

    @include breakpoint(sm) {
      line-height: 0.8;
    }
  }

  .servicios-block {
    min-height: rem(68);
    height: rem(68);

    @include breakpoint(sm) {
      min-height: rem(72);
      height: rem(72);
    }

    h4 {
      font-size: rem(16);

      @include breakpoint(lg) {
        font-size: rem(22);
      }
    }
  }

  .servicios--list {
    display: grid;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
