.testimonios {
  margin-top: rem(45);
  margin-bottom: 0;

  @include breakpoint(md) {
    margin-top: rem(80);
    margin-bottom: rem(50);
  }

  h2 {
    padding-top: rem(65);
    margin-bottom: rem(20);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 75%;
      height: rem(2);
      background-color: $gray;
      margin: 0 auto;
      margin-bottom: rem(65);

      @include breakpoint(md) {
        width: rem(425);
      }
    }
  }

  h6 {
    margin-top: rem(40);
  }

  &--control {
    border: 1px solid $black;
    width: rem(130);
    padding: rem(15) 0;
    margin: rem(25) auto;

    button {
      margin: 0 rem(16);

      svg {
        vertical-align: middle;
      }
    }
  }
}


.testimonio {
  padding-top: rem(50);

  p {
    font-family: $font-serif;
    color: $gray-dark;
    line-height: 1.75;
  }

  i {
    top: rem(25);
    left: 0;
    z-index: -1;
  }
}

.tns-nav {
  display: none;
}
