.mainFooter {
  background-color: $black;
  padding: rem(30) 0;

  &--logo {
    margin-top: rem(5);
    margin-bottom: rem(30);

    @include breakpoint(md) {
      margin-bottom: 0;
    }

    img {
      max-width: rem(152);
    }
  }

  p {
    color: $white;

    em {
      font-weight: $f-semibold;
      margin-bottom: rem(10);
    }
  }

  ul {
    margin-bottom: rem(30);

    @include breakpoint(md) {
      margin-bottom: 0;
    }

    li {
      color: $white;
      font-weight: $f-light;
      line-height: 1.875;

      a {
        color: $white;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 0;
          left: 0;
          margin: 0 auto;
          bottom: rem(-2);
          height: rem(1);
          background-color: $white;
          transition: width 0.225s ease;
        }

        &:hover {
          &:after {
            width: 100%;
          }
        }
      }


      svg {
        margin-right: rem(12);
      }
    }
  }
}

.scrollTop {
  background-color: $white;
  width: rem(50);
  height: rem(50);
  border-radius: 100%;
  margin-top: rem(75);
}


.hire {
  color: $white;
  text-decoration: underline;
  margin-top: rem(20);
}
