.promociones {
  padding-top: rem(75);
  margin-bottom: rem(25);
  margin-top: 0;

  @include breakpoint(md) {
    padding-top: rem(100);
    margin-top: 0;
    margin-bottom: rem(40);
  }

  &--main {
    background-image: url(../images/pattern.jpg);
    padding-top: rem(25);

    @include breakpoint(md) {
      padding-top: 0;
    }
  }


  p {
    color: $white;
    padding-top: rem(75);
  }

  h2 {
    top: 0;

    @include breakpoint(md) {
      top: rem(-75);
    }
  }
}

.promociones-block {
  margin-top: rem(50);
}

.promocion {
  background-color: $white;
  padding: rem(32);
  margin-bottom: rem(25);
  min-height: rem(260);

  @include breakpoint(sm) {
    min-height: rem(290);
  }

  h3 {
    margin-bottom: rem(35);
  }

  p {
    color: $black;
    padding-top: 0;
    margin-bottom: rem(30);
  }

  .btn {
    max-width: rem(300);
    width: 100%;
    height: rem(55);
    margin: 0 auto;
    font-size: rem(20);
    left: 0;
    right: 0;
    bottom: rem(20);
  }
}
