// Atomic typography
.i { font-style: italic; }
.fs-normal { font-style: normal; }

.ttc { text-transform: capitalize; }
.ttl { text-transform: lowercase; }
.ttu { text-transform: uppercase; }
.ttn { text-transform: none; }

.tl  { text-align: left; }
.tr  { text-align: right; }
.tc  { text-align: center; }
.tj  { text-align: justify; }

.tdn {
  text-decoration: none;
}

.underline    { text-decoration: underline; }
.no-underline { text-decoration: none; }


@each $name, $breakpoint in $breakpoints {
  @media (min-width: $breakpoint) {
    .tr-#{$name} {
      text-align: right;
    }

    .tl-#{$name} {
      text-align: left;
    }
  }
}
