*, *::before {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  min-height: 100%;
  height: 100%;
}

body {
  min-height: 100vh;
  background: $white;

  &.is-block {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
}

.is-hidden {
  position: absolute;
  height: 0;
  width: 0;
  visibility: hidden;
}


.main {
  padding-top: 0;

  @include breakpoint(sm) {
    padding-top: rem(80);
  }
}


input {
  @include reset-input;
}
