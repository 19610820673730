.contacto-hero {
  background-color: $black;
  padding: rem(55) 0;

  h2 {
    font-size: rem(60);
    margin-bottom: rem(10);
  }

  p {
    color: $white;
    margin: 0 auto;

    &:first-of-type {
      margin-bottom: rem(70);
      width: 50%;
    }
  }
}


.contacto {
  padding: rem(70) 0;

  form {
    max-width: rem(576);
    width: 100%;
    margin: 0 auto;
  }

  .input-group {
    margin-bottom: rem(22);
  }

  input,
  textarea {
    width: 100%;
    border: 2px solid $black;
    font-size: rem(16);
    font-family: $font;
    transition: border 0.225s ease;

    &:focus {
      outline: 0 none;
      border-color:$main-color;
    }
  }

  input {
    height: rem(60);
    padding: rem(10);
  }

  textarea {
    height: rem(172);
    resize: none;
    padding: rem(10);
  }

  label {
    font-weight: $f-bold;
    margin-bottom: rem(7);
    font-size: rem(14);
  }

  button {
    margin: 0 auto;
  }
}
