html {
  min-height: 100%;
  position: relative;
  font-family: $system-fonts;

  &.wf-active {
    font-family: $font;
  }
}

body {
  font: inherit;
  line-height: inherit;
}

input,
textarea {
  font-family: inherit;
}


a {
  text-decoration: none;
}


h1 {
  font-size: rem(42);
  font-weight: $f-bold;
  color: $white;
  text-align: center;
  text-shadow: $text-shadow;

  @include breakpoint(md) {
    font-size: rem(72);
  }
}


h2 {
  font-weight: $f-bold;
  font-size: rem(42);
  color: $gray;
  line-height: 1.15;

  @include breakpoint(sm) {
    font-size: rem(78);
  }

  @include breakpoint(md) {
    font-size: rem(120);
  }
}

h3 {
  font-weight: $f-semibold;
  font-size: rem(22);
  color: $black;

  @include breakpoint(sm) {
    font-size: rem(28);
  }

  @include breakpoint(md) {
    font-size: rem(34);
  }
}

h4 {
  font-weight: $f-semibold;
  font-size: rem(20);
  color: $black;

  @include breakpoint(sm) {
    font-size: rem(22);
  }
}

h5 {
  font-family: $font-serif;
  font-weight: $f-bold;
  position: relative;
  font-size: rem(24);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: rem(40);
    height: rem(2);
    background-color: $black;
  }
}

h6 {
  font-family: $font-serif;
  font-weight: $f-bold;
  font-size: rem(22);
}

p {
  font-weight: $f-light;
  line-height: 1.75;
}
