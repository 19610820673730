.servicios {
  padding-bottom: rem(50);

  h2 {
    margin-bottom: rem(5);
  }

  &--list {
    grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
    grid-column-gap: rem(30);
    grid-row-gap: rem(30);
    display: flex;
    overflow: scroll;
    padding-bottom: rem(20);
    margin-right: rem(-20);

    @include breakpoint(sm) {
      display: grid;
      overflow: inherit;
      padding-bottom: 0;
      margin-right: 0;
    }
  }
}

.servicios-block {
  border: 1px solid $gray-dark;
  padding: rem(16);
  position: relative;
  background: $white;
  min-height: rem(200);
  min-width: rem(280);
  margin-right: rem(20);

  @include breakpoint(sm) {
    margin-right: 0;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($main-color, 0.38);
    left: rem(10);
    top: rem(10);
    z-index: -1;
  }

  a {
    left: rem(15);
    bottom: rem(20);
    border: 1px solid $black;
    color: $black;
    background: #000;
    font-weight: $f-medium;
    padding: rem(5) rem(15);
    background-color: $white;

    &:hover {
      background-color: $black;
      color: $white;
    }
  }

  &--header {
    margin-bottom: rem(12);

    i {
      margin-right: rem(16);
    }
  }

  p {
    color: $gray-dark;
    font-weight: $f-light;
    line-height: 1.625;
    font-size: rem(14);

    @include breakpoint(sm) {
      font-size: rem(16);
    }
  }
}
