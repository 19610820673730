.habitacion-fotos {
  padding: rem(30) 0;

  h2 {
    margin-bottom: rem(10);

    @include breakpoint(md) {
      margin-bottom: 0;
    }
  }
}

.gallery {
  grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
  grid-column-gap: 1.875rem;
  grid-row-gap: 1.875rem;
  display: grid;
}

.gallery-item {
  height: rem(200);
  overflow: hidden;

  @include breakpoint(sm) {
    height: rem(400);
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
