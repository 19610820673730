.hero {
  height: rem(600);
  background-image: url(../images/hero.jpg);
  width: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include breakpoint(sm) {
    width: calc(100% - 40px);
    margin: 0 rem(20);
  }

  @include breakpoint(md) {
    height: rem(720);
  }

  &--content {
    margin-top: rem(40);

    @include breakpoint(md) {
      margin-top: rem(140);
    }
  }

  h1 {
    margin-bottom: rem(25);

    @include breakpoint(sm) {
      margin-bottom: rem(80);
    }
  }
}
